<script lang="ts" setup>
const isOpen = ref(false);

const close = () => {
  isOpen.value = false;
};
provide("close-form", close);

const users = ref([
  {
    name: "Me",
    value: "",
  },
]);
const apiHeaders = await useApiHeaders(true);
const openModal = async () => {
  isOpen.value = true;
  const data = await $fetch("/api/user", {
    headers: {
      ...apiHeaders.headers,
      iuid: selectedUser.value.value,
    },
  });
  users.value = [
    users.value[0],
    ...data.users.map((user) => ({ name: user.email, value: user.id })),
  ];
};
const selectedUser = ref({
  name: "Me",
  value: "",
});

const impersonatingUser = sessionStorage.getItem("iuname");

watch(selectedUser, (value) => {
  sessionStorage.setItem("iuid", value.value);
  sessionStorage.setItem("iuname", value.name);
  reloadNuxtApp();
});
</script>

<template>
  <div>
    <UButton
      size="sm"
      color="red"
      :variant="impersonatingUser != '' ? 'soft' : 'outline'"
      :label="
        impersonatingUser != ''
          ? `Impersonating ${impersonatingUser}`
          : ' Impersonate User'
      "
      :trailing="false"
      class="hidden sm:flex"
      @click="openModal" />

    <VimiModal v-model="isOpen" title="Select User">
      <div>
        <USelectMenu
          v-model="selectedUser"
          :options="users"
          option-attribute="name"
          placeholder="Select User"
          searchable
          class="w-full" />
      </div>
    </VimiModal>
  </div>
</template>

<script lang="ts" setup></script>

<style></style>
